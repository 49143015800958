import { render, staticRenderFns } from "./collectorInstitutionDataQuery.vue?vue&type=template&id=8fcac498&scoped=true&"
import script from "./collectorInstitutionDataQuery.vue?vue&type=script&lang=js&"
export * from "./collectorInstitutionDataQuery.vue?vue&type=script&lang=js&"
import style0 from "./collectorInstitutionDataQuery.vue?vue&type=style&index=0&id=8fcac498&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fcac498",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCheckbox,VForm,VRadio,VRadioGroup,VSelect})
