var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._m(1),(_vm.currentQueryInCreation.registeredUser)?_c('div',[_c('section',{staticClass:"container-fluid g-px-100--lg mb-10"},[(
          _vm.currentQueryInCreation.currentStep ==
            _vm.allConstants.queries.creationSteps.collectInstitutionData
        )?_c('div',{staticClass:"row"},[_vm._m(2)]):_vm._e()])]):_c('div',[_c('section',{staticClass:"container-fluid g-px-100--lg mb-10"},[(
          _vm.currentQueryInCreation.currentStep ==
            _vm.allConstants.queries.creationSteps.collectBasicData
        )?_c('div',{staticClass:"row"},[_vm._m(3)]):_vm._e(),(
          _vm.currentQueryInCreation.currentStep ==
            _vm.allConstants.queries.creationSteps.collectInstitutionData
        )?_c('div',{staticClass:"row"},[_vm._m(4)]):_vm._e()])]),(
      _vm.currentQueryInCreation.currentStep ==
        _vm.allConstants.queries.creationSteps.collectBasicData
    )?_c('collectorBasicData',{attrs:{"userType":_vm.currentQueryInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":true,"preloadData":_vm.currentQueryInCreation.collectedBasicData},on:{"enter":_vm.endCollectBasicData}}):_vm._e(),(
      _vm.currentQueryInCreation.currentStep ==
        _vm.allConstants.queries.creationSteps.collectInstitutionData
    )?_c('collectorInstitutionDataQuery',{attrs:{"userType":_vm.currentQueryInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":true,"preloadData":_vm.currentQueryInCreation.collectInstitutionData},on:{"enter":_vm.endCollectInstitutionData}}):_vm._e(),(
      _vm.currentQueryInCreation.currentStep ==
        _vm.allConstants.queries.creationSteps.collectRequestData
    )?_c('collectorRequestDataQuery',{attrs:{"userType":_vm.currentQueryInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":false,"preloadData":_vm.currentQueryInCreation.collectedRequestData},on:{"enter":_vm.endCollectRequestData,"back":_vm.currentQueryInCreationGoBackward}}):_vm._e(),(_vm.form.processing)?_c('section',{staticClass:"container-fluid g-px-140--lg g-px-50--sm mb-15"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan"}})],1)])]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.showResultAccountCreation),callback:function ($$v) {_vm.showResultAccountCreation=$$v},expression:"showResultAccountCreation"}},[_c('v-card',[_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('img',{attrs:{"src":"assets/img/message-3.svg"}})]),_c('div',{staticClass:"col-6"},[_vm._v("REGISTRO REALIZADO EXITOSAMENTE")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v(" Su cuenta ha sido creada con éxito en Defensoria del Cliente. ")])]),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"btn-main mt-10",on:{"click":function($event){_vm.showResultAccountCreation = false;
              _vm.currentQueryInCreationGoForeward();}}},[_vm._v("Continuar")])],1)])])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.showResultQueryCreation),callback:function ($$v) {_vm.showResultQueryCreation=$$v},expression:"showResultQueryCreation"}},[_c('v-card',[_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('img',{attrs:{"src":"assets/img/message-3.svg"}})]),_c('div',{staticClass:"col-6"},[_vm._v(" TU CONSULTA HA SIDO INGRESADA CON EL FOLIO N° "),_c('span',{staticClass:"g-color-blue g-font-weight-600"},[_vm._v(_vm._s(_vm.queryCreatedNumber))])])]),_c('p',{staticClass:"mt-3"},[_vm._v(" Es importante que guarde ese número pues le será requerido junto a su RUT para revisar el estado de su consulta desde la página(Botón Revisar). ")]),_c('v-btn',{staticClass:"btn-main mt-10",attrs:{"block":""},on:{"click":_vm.restart}},[_vm._v("Continuar")])],1)])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"g-bg-pos-center g-bg-size-cover",staticStyle:{"background-image":"url(assets/img/fondo-ingreso-wide.jpg)","height":"300px"}},[_c('div',{staticClass:"container g-color-white text-center g-py-210"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container-fluid g-px-100--lg mt-5"},[_c('h1',{staticClass:"g-font-weight-500 g-mb-15 font-color-title"},[_vm._v(" Ingresar una consulta ")]),_c('hr',{staticClass:"g-my-40",staticStyle:{"border":"1px solid","color":"#555"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12 col-lg-12 g-px-10 text-center"},[_c('img',{staticClass:"xs-display-none img-fluid",attrs:{"src":"assets/img/nav-reclamo-registrado-1.svg"}}),_c('img',{staticClass:"text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none",attrs:{"src":"assets/img/nav-reclamo-registrado-1-sm.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12 col-lg-12 g-px-10 text-center"},[_c('img',{staticClass:"xs-display-none img-fluid",attrs:{"src":"assets/img/nav-reclamo-1.svg"}}),_c('img',{staticClass:"text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none",attrs:{"src":"assets/img/nav-reclamo-1-sm.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12 col-lg-12 g-px-10 text-center"},[_c('img',{staticClass:"xs-display-none img-fluid",attrs:{"src":"assets/img/nav-reclamo-3.svg"}}),_c('img',{staticClass:"text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none",attrs:{"src":"assets/img/nav-reclamo-3-sm.svg"}})])}]

export { render, staticRenderFns }