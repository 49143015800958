<template>
  <div>
    <section
      class="g-bg-pos-center g-bg-size-cover"
      style="background-image: url(assets/img/fondo-ingreso-wide.jpg);height:300px;"
    >
      <div class="container g-color-white text-center g-py-210"></div>
    </section>
    <section class="container-fluid g-px-100--lg mt-5">
      <h1 class="g-font-weight-500 g-mb-15 font-color-title">
        Ingresar una consulta
      </h1>
      <hr class="g-my-40" style="border: 1px solid;color: #555;!important" />
    </section>
    <div v-if="currentQueryInCreation.registeredUser">
      <section class="container-fluid g-px-100--lg mb-10">
        <div
          class="row"
          v-if="
            currentQueryInCreation.currentStep ==
              allConstants.queries.creationSteps.collectInstitutionData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-registrado-1.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-registrado-1-sm.svg"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <section class="container-fluid g-px-100--lg mb-10">
        <div
          class="row"
          v-if="
            currentQueryInCreation.currentStep ==
              allConstants.queries.creationSteps.collectBasicData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-1.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-1-sm.svg"
            />
          </div>
        </div>

        <div
          class="row"
          v-if="
            currentQueryInCreation.currentStep ==
              allConstants.queries.creationSteps.collectInstitutionData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-3.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-3-sm.svg"
            />
          </div>
        </div>
      </section>
    </div>

    <collectorBasicData
      :userType="currentQueryInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="true"
      :preloadData="currentQueryInCreation.collectedBasicData"
      @enter="endCollectBasicData"
      v-if="
        currentQueryInCreation.currentStep ==
          allConstants.queries.creationSteps.collectBasicData
      "
    />

    <collectorInstitutionDataQuery
      :userType="currentQueryInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="true"
      :preloadData="currentQueryInCreation.collectInstitutionData"
      @enter="endCollectInstitutionData"
      v-if="
        currentQueryInCreation.currentStep ==
          allConstants.queries.creationSteps.collectInstitutionData
      "
    />

    <collectorRequestDataQuery
      :userType="currentQueryInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="false"
      :preloadData="currentQueryInCreation.collectedRequestData"
      @enter="endCollectRequestData"
      @back="currentQueryInCreationGoBackward"
      v-if="
        currentQueryInCreation.currentStep ==
          allConstants.queries.creationSteps.collectRequestData
      "
    />

    <section
      class="container-fluid g-px-140--lg g-px-50--sm mb-15"
      v-if="form.processing"
    >
      <div class="row">
        <div class="col-12">
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </div>
      </div>
    </section>

    <v-dialog v-model="showResultAccountCreation" persistent max-width="400">
      <v-card>
        <div class="pa-6">
          <div class="row">
            <div class="col-6"><img src="assets/img/message-3.svg" /></div>
            <div class="col-6">REGISTRO REALIZADO EXITOSAMENTE</div>
          </div>
          <div class="row">
            <div class="col-12">
              Su cuenta ha sido creada con éxito en Defensoria del Cliente.
            </div>
          </div>
          <div class="text-center">
            <v-btn
              class="btn-main mt-10"
              @click="
                showResultAccountCreation = false;
                currentQueryInCreationGoForeward();
              "
              >Continuar</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showResultQueryCreation" persistent max-width="400">
      <v-card>
        <div class="pa-6">
          <div class="row">
            <div class="col-6"><img src="assets/img/message-3.svg" /></div>
            <div class="col-6">
              TU CONSULTA HA SIDO INGRESADA CON EL FOLIO N°
              <span class="g-color-blue g-font-weight-600">{{
                queryCreatedNumber
              }}</span>
            </div>
          </div>
          <p class="mt-3">
            Es importante que guarde ese número pues le será requerido junto a
            su RUT para revisar el estado de su consulta desde la página(Botón
            Revisar).
          </p>
          <v-btn class="btn-main mt-10" block @click="restart">Continuar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import collectorBasicData from "@/components/PublicSite/User/collectorBasicData";
import collectorInstitutionDataQuery from "@/components/PublicSite/User/collectorInstitutionDataQuery";
import collectorRequestDataQuery from "@/components/PublicSite/User/collectorRequestDataQuery";

import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentQueryInCreationMixin from "@/mixins/currentQueryInCreation";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentQueryInCreationMixin],
  components: {
    collectorBasicData,
    collectorInstitutionDataQuery,
    collectorRequestDataQuery,
  },
  data() {
    return {
      formFields: {},
      showResultAccountCreation: false,
      showResultQueryCreation: false,
      queryCreatedNumber: 0,
    };
  },
  methods: {
    async endCollectBasicData(collectedData) {
      let currentQueryInCreation = this.currentQueryInCreation;
      currentQueryInCreation.collectedBasicData = collectedData;
      await this.currentQueryInCreationCreateOrUpdate(currentQueryInCreation);
      await this.currentQueryInCreationGoForeward();
    },
    async endCollectAddressData(collectedData) {
      this.initSignalProcessing();

      let currentQueryInCreation = this.currentQueryInCreation;
      currentQueryInCreation.collectedAddressData = collectedData;

      const createAccountResult = await this.createAccount();
      currentQueryInCreation.collectedBasicData.accountId =
        createAccountResult.data.id;

      await this.currentQueryInCreationCreateOrUpdate(currentQueryInCreation);

      this.showResultAccountCreation = true;

      /* El boton cerrar del dialogo invoca a this.currentQueryInCreationGoForeward() para avanzar en el flujo */
      this.stopSignalProcessing();
    },
    async endCollectInstitutionData(collectedData) {
      let currentQueryInCreation = this.currentQueryInCreation;
      currentQueryInCreation.collectedInstitutionData = collectedData.bank;
      currentQueryInCreation.collectedProductData = collectedData.products;
      await this.currentQueryInCreationCreateOrUpdate(currentQueryInCreation);
      await this.currentQueryInCreationGoForeward();
    },

    async endCollectRequestData(collectedData) {
      let currentQueryInCreation = this.currentQueryInCreation;
      currentQueryInCreation.collectedRequestData =
        collectedData.eventDescription;
      currentQueryInCreation.collectedDocumentsData =
        collectedData.documentData;
      await this.currentQueryInCreationCreateOrUpdate(currentQueryInCreation);
      await this.currentQueryInCreationGoForeward();
      await this.createQuery();
    },

    async createQuery() {
      this.initSignalProcessing();

      const creationResult = await this.createRealQuery();
      try {
        this.queryCreatedNumber = creationResult.data.folio;
        this.showResultQueryCreation = true;
      } catch (ex) {
        this.showInfoNotification(
          "Estimado usuario, por políticas de seguridad, no se encuentra permitido crear consultas de forma consecutiva. Favor intente más tarde."
        );
      }

      this.stopSignalProcessing();
    },
    restart() {
      this.currentQueryInCreationClear();

      if (this.currentUserIsAuthenticated) {
        this.replaceView("resume");
      } else {
        this.replaceView("begin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.font-color-title {
  color: #555;
  font-size: 2.5rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
</style>
