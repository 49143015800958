<template>
  <v-form v-model="form.isValid" @submit.prevent="enter">
    <section class="container-fluid g-px-140--lg g-px-50--sm">
      <div class="row">
        <h4 class="h5 g-font-weight-700 g-mb-1">
          Información del producto o servicio
        </h4>
        <div class="col-sm-12 col-lg-12 g-px-10">
          <div class="form-group mb-0">
            <div class="row">
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/bank.svg" /> Banco o institución</p>
                <div class="form-group g-mb-1">
                  <v-select
                    outlined
                    v-model.trim="formFields.banco"
                    :items="
                      banks.map((bank) => {
                        return { value: bank.code, text: bank.name };
                      })
                    "
                    placeholder="Selecciona uno de la lista"
                    required
                    :rules="formFields.bancoRules"
                    :disabled="banks.length == 0"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-12 g-px-10"></div>
            <div class="form-group mb-0">
              <div class="row">
                <div class="col-xl-12 col-lg-12 font-color-text">
                  <p class="g-mb-30">Debes seleccionar un producto</p>
                  <div class="g-mb-15 font-color-text">
                    <div class="form-check-inline u-check g-pl-25" v-if="false">
                      <v-checkbox
                        v-for="producto in products"
                        :key="producto.code"
                        v-model="formFields.products"
                        :rules="formFields.productsRules"
                        :label="producto.name"
                        :value="producto.code"
                        class="ml-2"
                      ></v-checkbox>
                    </div>
                    <div class="form-check-inline g-pl-25">
                      <v-radio-group
                        v-model="formFields.products"
                        row
                        :rules="formFields.productsRules"
                      >
                        <v-radio
                          v-for="producto in products"
                          :key="producto.code"
                          :label="producto.name"
                          :value="producto.code"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid text-center g-mb-60">
      <div class="row">
        <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
          <v-btn
            class="btn btn-lg btn-secondary g-mr-10 g-mb-15"
            :disabled="disableBackButton"
            :loading="form.processing"
            @click="back"
            >Volver</v-btn
          >
        </div>
        <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
          <v-btn
            type="submit"
            class="btn-main"
            :disabled="!form.isValid"
            :loading="form.processing"
            >Continuar</v-btn
          >
        </div>
      </div>
    </section>
  </v-form>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  props: {
    userType: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMainButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    preloadData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formFields: {
        banco: "",
        bancoRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexName.test(value) || "No es válido",
        ],
        products: [],
        productsRules: [(value) => value.length > 0 || "Requerido"],
      },
      banks: [],
      products: [],
    };
  },
  methods: {
    back() {
      this.$emit("back");
    },
    async enter() {
      this.initSignalProcessing();

      const collectedData = {
        bank: this.formFields.banco,
        products: [this.formFields.products],
      };

      this.$emit("enter", collectedData);
    },
  },
  async beforeMount() {
    this.initSignalLoading();

    //Get and prepare banks & regions list

    const parametersBank = this.$Parameters.all.find(
      (parameter) => parameter.type == "BankCodes"
    );
    this.banks = parametersBank.values;
    this.banks = this.banks.filter((bank) => bank.code !== "BAB");

    const parametersProducts = this.$Parameters.all.find(
      (parameter) => parameter.type == "ProductCodes"
    );
    this.products = parametersProducts.values;
    //if the current user is someone from bank then filter only to its bank

    //Preload data
    if (this.preloadData) {
      this.formFields.banco = this.preloadData.bank;

      this.formFields.products = this.preloadData.products;
    }

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped>
.font-color-name {
  color: #555;
  font-size: 17.5px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.font-color-text {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #555;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}
.font-color-subtitle {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff !important;
}
.font-color-title {
  color: #555;
  font-weight: bold;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
</style>
