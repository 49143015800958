<template>
  <v-form v-model="form.isValid" @submit.prevent="enter">
    <section class="container-fluid g-px-140--lg g-px-50--sm">
      <div class="row">
        <div class="col-sm-12 col-lg-12 g-px-10">
          <h4 class="h5 g-font-weight-700 g-mb-1">
            Detalle de la consulta
          </h4>
          <div class="form-group g-mb-1 font-color-text">
            <label class="g-mb-10" for="inputGroup2_1"
              ><img src="assets/img/message.svg" />Indícanos por favor tu
              consulta (máximo 1024 caracteres)</label
            >
            <v-textarea
              v-model.trim="formFields.suceso"
              required
              :rules="formFields.sucesoRules"
              maxlength="1024"
              outlined
              placeholder="¿Cuál es tu consulta?"
              counter
            ></v-textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-lg-12 g-px-10">
          <h4 class="h5 g-font-weight-700 g-mb-1">
            Documentos relacionados (Opcionales)
          </h4>
          <p>el formato de tus documentos puede ser Word o PDF</p>

          <div class="card rounded-0">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="g-bg-cyan g-color-white">
                    <th>Documento</th>
                    <th>Nombre de documento</th>
                    <th>Estado</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="documento in formFields.documentosRequeridos"
                    :key="documento.typeName"
                  >
                    <td>
                      {{ documento.typeName }}
                    </td>
                    <td>
                      <v-file-input
                        v-model="documento.blob"
                        :accept="allowedRequiredFileTypes.join(',')"
                        truncate-length="20"
                        :clearable="true"
                        :rules="formFields.documentosRequeridosRules"
                        :disabled="form.processing"
                      >
                      </v-file-input>
                    </td>
                    <td>
                      <span v-if="!documento.loading">{{
                        documento.blob == null ? "Pendiente" : "Seleccionado"
                      }}</span>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="blue-gre"
                      ></v-progress-circular>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid text-center g-mb-60">
      <div class="row">
        <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
          <v-btn
            class="btn btn-lg btn-secondary g-mr-10 g-mb-15"
            :disabled="disableBackButton"
            :loading="form.processing"
            @click="back"
            >Volver</v-btn
          >
        </div>
        <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
          <v-btn
            type="submit"
            class="btn-main"
            :disabled="!form.isValid"
            :loading="form.processing"
            >Continuar</v-btn
          >
        </div>
      </div>
    </section>
  </v-form>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import attachmentsService from "@/services/attachments";
export default {
  mixins: [baseViewMixin],
  props: {
    userType: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMainButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    preloadData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formFields: {
        suceso: "",
        sucesoRules: [
          (value) => !!value || "Requerido",
          (value) => value.length <= 4000 || "Demasiado largo",
          (value) =>
            this.allConstants.regex.regexComments.test(value) || "No es válido",
        ],
        documentosRequeridos: [
          {
            typeName: "archivo 1",
            required: false,
            blobId: "",
            selected: false,
            loading: false,
            loaded: false,
            realName: "",
            internalId: this.$uuid.v4(),
            blob: null,
          },
          {
            typeName: "archivo 2",
            required: false,
            blobId: "",
            selected: false,
            loading: false,
            loaded: false,
            realName: "",
            internalId: this.$uuid.v4(),
            blob: null,
          },
          {
            typeName: "archivo 3",
            required: false,
            blobId: "",
            selected: false,
            loading: false,
            loaded: false,
            realName: "",
            internalId: this.$uuid.v4(),
            blob: null,
          },
        ],
        documentosRequeridosRules: [
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) =>
            this.isValidRequiredFileType(value) || "Formato incorrecto",
          (value) => this.isValidFileSize(value) || "Tamaño máximo 10 Mb",
        ],
      },
      allowedRequiredFileTypes: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "image/gif",
        "image/jpeg",
        "image/tiff",
        "image/svg+xml",
        "image/png",
      ],
    };
  },
  methods: {
    isValidFileName(fileInfo) {
      if (fileInfo == null) return true;

      return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
    },
    isValidFileSize(fileInfo) {
      if (fileInfo == null) return true;

      return fileInfo.size <= 10485760; //10 mg segun requerimiento como tamaño maximo
    },
    isValidRequiredFileType(fileInfo) {
      if (fileInfo == null) return true;
      if (fileInfo.size === 0) return false;

      return (
        this.allowedRequiredFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },

    back() {
      this.$emit("back");
    },
    async enter() {
      this.initSignalProcessing();

      const uploadFileColecction = [];

      this.formFields.documentosRequeridos.forEach((document) => {
        if (document.blob != null) {
          document.selected = true;
          document.realName = document.blob.name;
          document.fileSize = document.blob.size;
          document.fileContentType = document.blob.type;

          uploadFileColecction.push(
            this.uploadDocument(document.internalId, document.blob)
          );

          document.loading = true;
        }
      });

      const uploadFilesResult = await Promise.all(uploadFileColecction);
      uploadFilesResult.forEach((uploadResult) => {
        this.formFields.documentosRequeridos.forEach((document) => {
          if (document.internalId == uploadResult.internalId) {
            document.blobId = uploadResult.blobId;
            document.loading = false;
            document.loaded = true;
          }
        });
      });

      // let collectedData = this.formFields.documentosRequeridos
      //   .filter((document) => document.blobId != "")
      //   .map((document) => {
      //     return {
      //       typeName: document.typeName,
      //       realName: document.realName,
      //       blobId: document.blobId,
      //       blobSize: document.fileSize,
      //       blobContentType: document.fileContentType,
      //     };
      //   });

      const collectedData = {
        eventDescription: this.formFields.suceso,
        documentData: this.formFields.documentosRequeridos
          .filter((document) => document.blobId != "")
          .map((document) => {
            return {
              typeName: document.typeName,
              realName: document.realName,
              blobId: document.blobId,
              blobSize: document.fileSize,
              blobContentType: document.fileContentType,
            };
          }),
      };
      this.$emit("enter", collectedData);
    },
    async uploadDocument(internalId, blob) {
      const addResult = await attachmentsService.add(blob);

      return {
        internalId: internalId,
        blobId: addResult.data.blobId,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
